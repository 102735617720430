<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title">Year</h3>
      </div>
      <div class="card-body">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import getYear from 'date-fns/getYear'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'YearTrafficNew',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    transactions: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      parsedTransactions: [],
      coinTransactions: [],
      smsTransactions: [],
      loyaltyTransactions: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories: ['2023'],
          labels: {
            show: true,
            rotate: -90,
            minHeight: 0,
            maxHeight: 300,
            style: {
              colors: [],
              fontSize: '12px',
              fontWeight: 400,
            },
            offsetX: 0,
            offsetY: 0,
            formatter: (value) => {
              return value
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    if (this.currentUserCompanyModule[0] === 'gaming') {
      this.options.legend.show = true
    }

    const mq = window.matchMedia('(min-width: 480px)')
    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    this.transactions.forEach((transaction) => {
      this.parsedTransactions.push({
        year: getYear(new Date(transaction.createdAt)),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.coinTransactions = this.parsedTransactions.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
    )

    this.smsTransactions = this.parsedTransactions.filter(
      (transaction) => transaction.paymentType === 'pay_sms'
    )

    this.loyaltyTransactions = this.parsedTransactions.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard'
    )

    let totalCoin = 0
    this.coinTransactions.forEach((transaction) => {
      totalCoin += parseFloat(transaction.amount)
    })
    this.series[0].data.push(totalCoin)

    let totalSms = 0
    this.smsTransactions.forEach((transaction) => {
      totalSms += parseFloat(transaction.amount)
    })
    this.series[1].data.push(totalSms)

    let totalLoyalty = 0
    this.loyaltyTransactions.forEach((transaction) => {
      totalLoyalty += parseFloat(transaction.amount)
    })
    this.series[2].data.push(totalLoyalty)

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {},
}
</script>
